import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {FormGroup} from "@angular/forms";

import {Utils} from "../helpers/utils";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    constructor(private http: HttpClient) {
    }

    public getApiUrl(url: string, version: number = 1, service: string = '') {
        if (service) {
            return environment.apiUrl + `/${service}/v${version}${url}`;
        }
        return environment.apiUrl + `/v${version}${url}`;
    }

    public getApiUrlV2(url: string, version: number = 1, service: string = '') {
        if (service) {
            return environment.apiUrlV2 + `/${service}/v${version}${url}`;
        }
        return environment.apiUrlV2 + `/v${version}${url}`;
    }

    public async get<T>(url: string, params?: any): Promise<IHttpResult<T>> {
        try {
            let r = await this.http.get<T>(url, {
                params: params
            }).toPromise();
            return new HttpResult<T>('OK', r);
        } catch (err) {
            if (err.status === 401) {
                return new HttpResult<T>('UNAUTHORIZED');
            } else if (err.status === 403) {
                return new HttpResult<T>('FORBIDDEN');
            } else {
                return new HttpResult<T>('ERROR');
            }
        }
    }

    public async put<T>(url: string, form: FormGroup = null, payload: any = null): Promise<IHttpResult<T>> {

        if (form) {
            Utils.clearRemoteErrors(form);
            Utils.validateAllFormFields(form);

            if (!form.valid) {
                return new HttpResult<T>('INVALID');
            }
        }

        try {
            let r = await this.http.put<T>(url, payload ? payload : form.value).toPromise();

            return new HttpResult<T>('OK', r);
        } catch (err) {
            if (err.status === 400) {
                if (form) {
                    Utils.applyRemoteErrors(form, err.error.errors);
                }
                return new HttpResult<T>('INVALID', err.error.errors);
            } else if (err.status === 401) {
                return new HttpResult<T>('UNAUTHORIZED');
            } else if (err.status === 403) {
                return new HttpResult<T>('FORBIDDEN');
            } else {
                return new HttpResult<T>('ERROR');
            }
        }
    }

    public async post<T>(url: string, form: FormGroup = null, payload: any = null): Promise<IHttpResult<T>> {

        if (form) {
            Utils.clearRemoteErrors(form);
            Utils.validateAllFormFields(form);
            if (!form.valid) {
                return new HttpResult<T>('INVALID');
            }
        }

        try {
            let r = await this.http.post<T>(url, payload ? payload : (form ? form.value : {})).toPromise();

            return new HttpResult<T>('OK', r);
        } catch (err) {
            if (err.status === 400) {
                if (form) {
                    Utils.applyRemoteErrors(form, err.error.errors);
                }
                return new HttpResult<T>('INVALID', err.error.errors);
            } else if (err.status === 401) {
                return new HttpResult<T>('UNAUTHORIZED');
            } else if (err.status === 403) {
                return new HttpResult<T>('FORBIDDEN');
            } else {
                return new HttpResult<T>('ERROR');
            }
        }
    }

    public async postRaw<T>(url: string, form: FormGroup = null, payload: any): Promise<IHttpResult<T>> {

        if (form) {
            Utils.clearRemoteErrors(form);
            Utils.validateAllFormFields(form);
            if (!form.valid) {
                return new HttpResult<T>('INVALID');
            }
        }

        try {
            let r = await this.http.post<T>(url, payload).toPromise();

            return new HttpResult<T>('OK', r);
        } catch (err) {
            if (err.status === 400) {
                if (form) {
                    Utils.applyRemoteErrors(form, err.error);
                }
                return new HttpResult<T>('INVALID', err.error.errors);
            } else if (err.status === 401) {
                return new HttpResult<T>('UNAUTHORIZED');
            } else if (err.status === 403) {
                return new HttpResult<T>('FORBIDDEN');
            } else {
                return new HttpResult<T>('ERROR');
            }
        }
    }

    public async putRaw<T>(url: string, form: FormGroup = null, payload: any): Promise<IHttpResult<T>> {

        if (form) {
            Utils.clearRemoteErrors(form);
            Utils.validateAllFormFields(form);
            if (!form.valid) {
                return new HttpResult<T>('INVALID');
            }
        }

        try {
            let r = await this.http.put<T>(url, payload).toPromise();

            return new HttpResult<T>('OK', r);
        } catch (err) {
            if (err.status === 400) {
                if (form) {
                    Utils.applyRemoteErrors(form, err.error);
                }
                return new HttpResult<T>('INVALID', err.error.errors);
            } else if (err.status === 401) {
                return new HttpResult<T>('UNAUTHORIZED');
            } else if (err.status === 403) {
                return new HttpResult<T>('FORBIDDEN');
            } else {
                return new HttpResult<T>('ERROR');
            }
        }
    }

    public async delete(url: string, form: FormGroup = null): Promise<IHttpResult<void>> {

        if (form) {
            Utils.clearRemoteErrors(form);
            Utils.validateAllFormFields(form);

            if (!form.valid) {
                return new HttpResult<void>('INVALID');
            }
        }

        try {
            let r = await this.http.delete(url).toPromise();

            return new HttpResult<void>('OK');
        } catch (err) {
            if (err.status === 400) {
                if (form) {
                    Utils.applyRemoteErrors(form, err.error);
                }
                return new HttpResult<void>('INVALID', err.error.errors);
            } else if (err.status === 401) {
                return new HttpResult<void>('UNAUTHORIZED');
            } else if (err.status === 403) {
                return new HttpResult<void>('FORBIDDEN');
            } else {
                return new HttpResult<void>('ERROR');
            }
        }
    }
}

export interface IHttpResult<T> {
    status: string;
    data?: T;
    isReady: boolean;
    isInvalid: boolean;
    isOk: boolean;
    isPending: boolean;
    hasError: boolean;
    hasPermission: boolean;
}

export class HttpResult<T> implements IHttpResult<T> {
    public data: T;
    public status: string;

    private static _pending = new HttpResult<any>();
    private static _none = new HttpResult<any>('NONE');
    private static _ok = new HttpResult<any>('OK');

    constructor(status?: string, data?: T) {
        this.status = status || null;
        this.data = data || null;
    }

    get isReady(): boolean {
        return this.status !== null;
    }

    get isPending(): boolean {
        return this.status === 'PENDING';
    }

    get isOk(): boolean {
        return this.status === 'OK';
    }

    get isInvalid(): boolean {
        return this.status === 'INVALID';
    }

    get hasError(): boolean {
        return this.status === 'ERROR';
    }

    get hasPermission(): boolean {
        return !(this.status === 'UNAUTHORIZED' || this.status === 'FORBIDDEN');
    }

    static get pending(): HttpResult<any> {
        return this._pending;
    }

    static get ok(): HttpResult<any> {
        return this._ok;
    }

    static get none(): HttpResult<any> {
        return this._none;
    }
}
