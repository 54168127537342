import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {AuthService} from "./core/services";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    public isExpanded = false;

    constructor(
        private router: Router,
        private authService: AuthService
    ) {
    }

    toggle(): void {
        this.isExpanded = !this.isExpanded;
    }

    ngOnInit(): void {
        this.authService.init().then(authenticated => {

            console.log(`Authentication result: ${authenticated}`);

            /*if (authenticated) {
                //let url = this.authService.state() || window.location.pathname;
                let url = '';
                console.log(`Authenticated, redirecting to ${url} ...`);
                this.router.navigateByUrl(url);
            } else {
                console.log('Not Authenticated!');
                this.router.navigate(['/login']);
                // this.authService.login(window.location.pathname);
            }*/
        });

        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        });
    }

    get isAuthenticated(): boolean {
        return this.authService.isAuthenticated;
    }
}
