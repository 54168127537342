export class Money {
    unitValue: number;
    currencyCode: string;

    static formatCurrency(unitValue: number, currencyCode: string): string {
        if (currencyCode === 'GBP') {
            return `&pound;${(unitValue / 100).toLocaleString()}`;
        }
        if (currencyCode === 'USD') {
            return `$${(unitValue / 100).toLocaleString()}`;
        }
        if (currencyCode === 'EUR') {
            return `€${(unitValue / 100).toLocaleString()}`;
        }

        return `${unitValue} ${currencyCode}`;
    }
}
