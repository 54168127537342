import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../core/services";

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {

    constructor(
        private authService: AuthService
    ) {

    }

    ngOnInit(): void {
        this.authService.logout().then(() => {

        });
    }
}
