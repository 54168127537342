import {Subject} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class EventService {
    private listeners = {};
    private eventsSubject = new Subject();
    private events = null;

    constructor() {
        this.events = this.eventsSubject.asObservable();
        this.events.subscribe(({name, args}) => {
            if (this.listeners[name]) {
                for (const listener of this.listeners[name]) {
                    listener(...args);
                }
            }
        });
    }

    on(name, listener) {
        if (!this.listeners[name]) {
            this.listeners[name] = [];
        }

        this.listeners[name].push(listener);

        return (() => {
            const n = name;
            const f = listener;

            return () => {
                const index = (<Array<Function>>this.listeners[n]).indexOf(f);
                (<Array<Function>>this.listeners[n]).splice(index, 1);
            };
        })();
    }

    broadcast(name, ...args) {
        this.eventsSubject.next({name, args});
    }
}
