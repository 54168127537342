const menuGroups = [
    {
        text: 'Navigation',
        items: [
            {
                href: "/dashboard",
                text: "Dashboard"
            },
            {
                href: "/affiliates",
                text: "Affiliates"
            },
            {
                href: "/clients",
                text: "Clients"
            },
            {
                href: "/subscriptions",
                text: "Subscriptions"
            },
            {
                href: "/payments",
                text: "Payments"
            },
            {
                href: "/login",
                text: "login"
            },
            {
                href: "/logout",
                text: "logout"
            }
        ]
    },
    {
        text: 'Products',
        items: [
            {
                href: "/products",
                text: "Products"
            },
            {
                href: "/product-groups",
                text: "Product Groups"
            },
            {
                href: "/payment-gateways",
                text: "Payment Gateways"
            },
        ]
    },
    {
        text: 'Scheduling',
        items: [
            {
                href: "/payment-schedules",
                text: "Payment Schedules"
            },
            {
                href: "/sort-code-times",
                text: "Sort Code Times"
            },
        ]
    },
    {
        text: 'Reporting',
        items: [
            {
                href: "/clients/lifetime-report",
                text: "Client Lifetime"
            },
            {
                href: "/payment-gateways/report",
                text: "Payment Gateways"
            },
        ]
    },
    {
        text: 'Administration',
        items: [
            {
                href: "/acquirers",
                text: "Acquirers"
            },
            {
                href: "/users",
                text: "Users"
            },
        ]
    }
];

export function getMenuItems(): IMenuGroup[] {
    return menuGroups;
}

export interface IMenuGroup {
    text: string;
    items: IMenuItem[];
}

export interface IMenuItem {
    text: string;
    href: string;
}
