import {Injectable} from "@angular/core";

import {EventService} from "./event.service";
import {AuthService} from "./auth.service";
import {ISelectListItem} from "../../shared/interfaces";

//import {SelectListItem} from "../../shared/selectlistitem";

@Injectable()
export class CoreService {
    constructor(private authService: AuthService, private eventService: EventService) {
    }

    public init(): void {

        /*return this.authService.init().then(() => {
            if (this.authService.isAuthenticated) {
                console.log("Authenticated");
                this.eventService.broadcast("logged_in", {});
            } else {
                console.log("Unauthenticated");
                this.eventService.broadcast("logged_out", {});
            }
        });*/
    }
}

@Injectable()
export class LanguageService {

    /*public languages: SelectListItem[] = [
        {value: 'en-GB', text: 'English (Great Britain)'},
        {value: 'en-US', text: 'English (United States)'},
        {value: 'fr-FR', text: 'French'},
        {value: 'pl-PL', text: 'Polish'},
        {value: 'sk-SK', text: 'Slovak'},
        {value: 'ja-JP', text: 'Japanese'}
    ];*/

}

@Injectable({
    providedIn: 'root'
})
export class CurrencyService {

    public currencies: ISelectListItem[] = [
        {value: 'GBP', text: '(£) GBP'},
        {value: 'USD', text: '($) USD'},
        {value: 'EUR', text: '(€) EUR'},
    ];

    public getSymbol(code: string): string {
        switch (code) {
            case "GBP":
                return "£";
            case "USD":
                return "$";
            case "EUR":
                return "€";
            default :
                return '¤';
        }
    }
}
