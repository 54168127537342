import {FormGroup, FormControl, FormArray, AbstractControl} from "@angular/forms";

export class Utils {
    static toLowerFirst(value: string): string {
        if (!value || value.length < 1) {
            return "";
        }

        return value[0].toLowerCase() + value.substring(1);
    }

    static scrollToTop() {
        try {
            window.scrollTo({left: 0, top: 0, behavior: "smooth"});
        } catch (e) {
            window.scrollTo(0, 0);
        }
    }

    static validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({onlySelf: true});
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            } else if (control instanceof FormArray) {
                (<FormArray>control).controls.forEach(c => {
                    this.validateAllFormFields(c as FormGroup);
                });
            }
        });
    }

    static enableAllFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.enable({onlySelf: true});
            } else if (control instanceof FormGroup) {
                this.enableAllFields(control);
            } else if (control instanceof FormArray) {
                (<FormArray>control).controls.forEach(c => {
                    this.enableAllFields(c as FormGroup);
                });
            }
        });
    }

    static disableAllFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.disable({onlySelf: true});
            } else if (control instanceof FormGroup) {
                this.disableAllFields(control);
            } else if (control instanceof FormArray) {
                (<FormArray>control).controls.forEach(c => {
                    this.disableAllFields(c as FormGroup);
                });
            }
        });
    }

    static applyRemoteErrors(form: FormGroup, errors: { [key: string]: string[] }) {
        for (let k in errors) {
            let control = form.get(this.pathToCamelCase(k)) as AbstractControl;
            if (control) {
                control.setErrors({remote: errors[k]});
                control.markAsTouched();
            }
        }
    }

    static clearRemoteErrors(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                if (control.errors && control.errors["remote"]) {
                    delete control.errors["remote"];
                    if (!Object.keys(control.errors).length) {
                        control.setErrors(null);
                    }
                    //control.updateValueAndValidity({ onlySelf: true })
                }
            } else if (control instanceof FormGroup) {
                this.clearRemoteErrors(control);
            } else if (control instanceof FormArray) {
                (<FormArray>control).controls.forEach(c => {
                    this.clearRemoteErrors(c as FormGroup);
                });
            }
        });
    }

    static pathToCamelCase(path: string) {
        let parts = path.split('.');
        if (parts.length == 0 || parts[0] == '') {
            return '';
        }
        return parts.map(part => part[0].toLocaleLowerCase() + part.substring(1)).join('.');
    }

    static humanFileSize(bytes, si) {
        var thresh = si ? 1000 : 1024;
        if (Math.abs(bytes) < thresh) {
            return bytes + " B";
        }
        var units = si ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
        var u = -1;
        do {
            bytes /= thresh;
            ++u;
        } while (Math.abs(bytes) >= thresh && u < units.length - 1);
        return bytes.toFixed(1) + " " + units[u];
    }
}
