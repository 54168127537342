import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../core/services";
import {Router} from "@angular/router";

@Component({
    selector: 'app-login-callback',
    templateUrl: './login-callback.component.html',
})
export class LoginCallbackComponent implements OnInit {

    constructor(
        private authService: AuthService,
        private router: Router
    ) {

    }

    ngOnInit(): void {
        this.authService.redirectCallback().then(() => {
            console.log('Processed redirect callback, redirecting...');
            this.router.navigate(['/']).catch(console.error);
        });
    }
}
