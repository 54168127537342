import {Component, OnDestroy} from '@angular/core';
import {getMenuItems, IMenuGroup} from "../menu";
import {AuthService} from "../../core/services";
import {Subscription} from "rxjs";

@Component({
    selector: '[app-desktop-menu]',
    templateUrl: './desktop-menu.component.html'
})
export class DesktopMenuComponent implements OnDestroy {
    isExpanded = false;

    public user: any = null;
    public sub: Subscription = null;

    constructor(authService: AuthService) {
        this.user = authService.user;
        this.sub = authService.currentUser.subscribe((user) => {
            this.user = user;
        })
    }

    toggle() {
        this.isExpanded = !this.isExpanded;
    }

    get menuItems(): IMenuGroup[] {
        return getMenuItems();
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }
}
