import {Injectable} from '@angular/core';

import {WorkflowService} from './workflow.service';

import {CompleteActionComponent} from './actions/complete-action.component';
import {StartActionComponent} from './actions/start-action.component';
import {WaitActionComponent} from "./actions/wait-action.component";
import {AuthorizePaymentActionComponent} from "./actions/authorize-payment-action.component";
import {CapturePaymentActionComponent} from "./actions/capture-payment-action.component";
import {JumpWorkflowActionComponent} from "./actions/jump-workflow-action.component";
import {ParallelActionComponent} from "./actions/parallel-action.component";
import {ConditionalActionComponent} from "./actions/conditional-action.component";

@Injectable()
export class WorkflowActionRegistry {

    private editors: any = {
        'start-action': {component: StartActionComponent, name: 'Start'},
        'e3e44e7e-0af7-499d-878e-a0107e7269f6': {component: CompleteActionComponent, name: 'Complete'},
        'wait-action': {component: WaitActionComponent, name: 'Wait'},
        '276836eb-ece7-4e03-ac05-8d182ffcba93': {component: AuthorizePaymentActionComponent, name: 'Authorize Payment'},
        'b3cff627-09e3-48ef-90dd-a48465e7a3bd': {component: CapturePaymentActionComponent, name: 'Capture Payment'},
        '527be597-b116-439c-a96d-7c57bae8023d': {component: JumpWorkflowActionComponent, name: 'Jump To Payment Flow'},
        'cfc23b62-5e28-47b1-beeb-25dfbb756107': {component: ParallelActionComponent, name: 'Run In Parallel'},
        '2e69afcc-d06c-415a-b021-c02693cbaec7': {component: ConditionalActionComponent, name: 'Conditional'},
    };

    /**
     *
     */
    constructor(private workflowService: WorkflowService) {

    }

    public initialize() {
        this.workflowService.setActions(this.editors);
    }
}
