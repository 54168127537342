import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {AppComponent} from './app.component';
import {HomeComponent} from './features/home/home.component';
import {MobileMenuComponent} from "./layout/mobile-menu/mobile-menu.component";
import {DesktopMenuComponent} from "./layout/desktop-menu/desktop-menu.component";
import {CoreModule} from "./core/core.module";
import {LoginComponent} from "./features/auth/login.component";
import {LoginCallbackComponent} from "./features/auth/login-callback.component";
import {LogoutComponent} from "./features/auth/logout.component";
import {TokenInterceptor} from "./core/interceptors/token-interceptor";
import {SharedModule} from "./shared/shared.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AuthGuard} from "./core/guards/auth.guard";

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        MobileMenuComponent,
        DesktopMenuComponent,
        LoginComponent,
        LoginCallbackComponent,
        LogoutComponent
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
        HttpClientModule,
        FormsModule,
        CoreModule,
        SharedModule,
        BrowserAnimationsModule,
        FontAwesomeModule,
        RouterModule.forRoot([
            {path: 'login', component: LoginComponent},
            {path: 'logout', component: LogoutComponent},
            {path: 'auth/callback', component: LoginCallbackComponent},

            {
                path: 'dashboard',
                component: HomeComponent,
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: 'Dashboard'
                },
                pathMatch: 'full'
            },
            //
            {
                path: 'affiliates',
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: 'Affiliates'
                },
                loadChildren: () => import('./features/affiliates/affiliate.module').then(m => m.AffiliateModule)
            },
            {
                path: 'acquirers',
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: 'Acquirer'
                },
                loadChildren: () => import('./features/acquirers/acquirer.module').then(m => m.AcquirerModule)
            },
            {
                path: 'clients',
                data: {
                    breadcrumb: 'Clients'
                },
                loadChildren: () => import('./features/clients/client.module').then(m => m.ClientModule)
            },
            {
                path: 'payment-gateways',
                data: {
                    breadcrumb: 'Payment Gateways'
                },
                loadChildren: () => import('./features/payment-gateways/payment-gateway.module').then(m => m.PaymentGatewayModule)
            },
            {
                path: 'subscriptions',
                data: {
                    breadcrumb: 'Subscriptions'
                },
                loadChildren: () => import('./features/subscriptions/subscription.module').then(m => m.SubscriptionModule)
            },
            {
                path: 'payments',
                data: {
                    breadcrumb: 'Payments'
                },
                loadChildren: () => import('./features/payments/payments.module').then(m => m.PaymentModule)
            },
            {
                path: 'payment-schedules',
                data: {
                    breadcrumb: 'Payment Schedules'
                },
                loadChildren: () => import('./features/payment-schedules/payment-schedule.module').then(m => m.PaymentScheduleModule)
            },
            {
                path: 'payment-workflows',
                data: {
                    breadcrumb: 'Payment Workflows'
                },
                loadChildren: () => import('./features/payment-flows/payment-flow.module').then(m => m.PaymentFlowModule)
            },
            {
                path: 'leads',
                data: {
                    breadcrumb: 'Leads'
                },
                loadChildren: () => import('./features/leads/lead.module').then(m => m.LeadModule)
            },
            {
                path: 'products',
                data: {
                    breadcrumb: 'Products'
                },
                loadChildren: () => import('./features/products/product.module').then(m => m.ProductModule)
            },
            {
                path: 'product-groups',
                data: {
                    breadcrumb: 'Product Groups'
                },
                loadChildren: () => import('./features/product-groups/product-group.module').then(m => m.ProductGroupModule)
            },
            {
                path: 'sort-code-times',
                data: {
                    breadcrumb: 'Sort Code Times'
                },
                loadChildren: () => import('./features/sort-code-times/sort-code-time.module').then(m => m.SortCodeTimeModule)
            },
            {path: '**', redirectTo: '/dashboard', pathMatch: 'full'}
        ])
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
