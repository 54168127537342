<app-textbox [control]="form.get('label')" label="Label"></app-textbox>

<h4 class="mt-6 text-lg font-weight-bold leading-6">
    Settings
</h4>

<p class="mb-3 font-weight-light text-sm text-gray-500">
    Set the duration of the wait.
</p>

<div class="grid grid-cols-6 gap-2">
    <app-textbox [control]="form.get('settings.EXPRESSION')" label="Expression"></app-textbox>
</div>

<h4 class="mt-6 text-lg font-weight-bold leading-6">
    Links
</h4>

<p class="mb-3 font-weight-light text-sm text-gray-500">
    Set the direction of the flow.
</p>

<app-selectbox label="On Success"
               [control]="form.get('successLink')"
               [items]="getLinkItems()"
               addNone="true"></app-selectbox>
