import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

import {WorkflowService} from '../workflow.service';

import {IGraphLink, GraphNode, IWorkflowActionComponent} from '../workflow-action.component';


@Component({
    templateUrl: './complete-action.component.html'
})
export class CompleteActionComponent implements IWorkflowActionComponent, OnInit {
    @Input() id: string;
    @Input() workflowActionId: string;
    @Input() node: any;

    @Input() nodes: GraphNode[];
    @Input() links: IGraphLink[];

    public form: FormGroup;
    public submitting: boolean = false;

    constructor(
        private fb: FormBuilder,
        private workflowService: WorkflowService
    ) {
        this.form = this.fb.group({
            'label': this.fb.control('', []),
            'settings': this.fb.group({

            })
        });
    }

    ngOnInit(): void {
        this.form.patchValue({
            'label': this.node.label,
            'settings': this.node.settings
        });
    }

    getTargetNodes(): GraphNode[] {
        return this.nodes.filter(node => { return node.id != this.id; });
    }

    getTargetName(node: GraphNode) {
        let editor = this.workflowService.getAction(node.workflowActionId);
        return node.label || editor.name;
    }

    getLink(type: number) {
        return this.links.find((element) => {
            return element.source == this.id && element.linkType == type;
        });
    }

    save() {
        let data = this.form.value;

        let links: IGraphLink[] = [];

        return { data, links };
    }
}
