import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

import {WorkflowService} from '../workflow.service';

import {IGraphLink, GraphNode, IWorkflowActionComponent} from '../workflow-action.component';
import {ISelectListItem} from "../../../interfaces";

@Component({
    templateUrl: './conditional-action.component.html'
})
export class ConditionalActionComponent implements IWorkflowActionComponent, OnInit {
    @Input() id: string;
    @Input() workflowActionId: string;
    @Input() node: any;

    @Input() nodes: GraphNode[];
    @Input() links: IGraphLink[];

    public form: FormGroup;

    public targetLinks: ISelectListItem[] = [];

    constructor(
        private fb: FormBuilder,
        private workflowService: WorkflowService
    ) {
        this.form = this.fb.group({
            'label': this.fb.control('', []),
            'settings': this.fb.group({
                'EXPRESSION': this.fb.control('', []),
            }),
            'successLink': this.fb.control('', []),
            'failureLink': this.fb.control('', [])
        });
    }

    ngOnInit(): void {
        let successLink = this.getLink(0);
        let failureLink = this.getLink(1);

        this.form.patchValue({
            'label': this.node.label,
            'settings': this.node.settings,
            'successLink': successLink ? successLink.target : '',
            'failureLink': failureLink ? failureLink.target : ''
        });

        this.nodes.forEach(node => {

            if (node.id == this.id) {
                return;
            }

            this.targetLinks.push({
                text: "Go to '" + this.getTargetName(node) + "'",
                value: node.id
            });
        });
    }

    getTargetName(node: GraphNode) {
        let editor = this.workflowService.getAction(node.workflowActionId);
        return node.label || editor.name;
    }

    getLinkItems(): ISelectListItem[] {
        return this.targetLinks;
    }

    getLink(type: number) {
        return this.links.find((element) => {
            return element.source == this.id && element.linkType == type;
        });
    }

    save() {
        let data = this.form.value;

        let links: IGraphLink[] = [];

        if (data.successLink) {
            links.push({
                linkType: 0,
                source: this.id,
                target: data.successLink
            });
        }

        if (data.failureLink) {
            links.push({
                linkType: 1,
                source: this.id,
                target: data.failureLink
            });
        }

        return {data, links};
    }
}
