import {Directive, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from "@angular/core";
import {AuthService} from "./services";
import {Subscription} from "rxjs";

@Directive({
    selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit, OnDestroy {
    private currentUser;
    private permissions = [];

    private sub: Subscription;
    private created = false;

    constructor(
        private element: ElementRef,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authService: AuthService
    ) {
    }

    ngOnInit() {
        this.sub = this.authService.currentUser.subscribe(user => {
            this.currentUser = user;
            console.log('[hasPermission] received user update, updating view...');
            this.updateView();
        });
        this.currentUser = this.authService.user;
    }

    ngOnDestroy() {
        this.sub?.unsubscribe();
    }

    @Input()
    set hasPermission(val) {
        this.permissions = val;
        this.updateView();
    }

    private updateView() {

        const result = this.currentUser && this.checkPermission();

        if (result) {
            if (!this.created) {
                console.log('[hasPermission] creating embedded view, auth result: ', result);
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.created = true;
            }
        } else {
            this.viewContainer.clear();
            this.created = false;
        }
    }

    private checkPermission() {
        if (this.permissions.length > 0) {

            if (this.currentUser && this.currentUser.permissions) {
                // There are permissions required but user has none.
                return false;
            }

            for (const checkPermission of this.permissions) {
                const permissionFound = this.currentUser.permissions.find(x => x.toUpperCase() === checkPermission.toUpperCase());
            }

            return true;
        } else {
            return true;
        }
    }
}
