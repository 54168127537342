export const environment = {
    production: true,
    apiUrl: 'https://api.ladon.cloud',
    apiUrlV2: 'https://api-v2.ladon.cloud',
    authority: 'https://identity.ladon.cloud',
    client_id: 'ladon-portal',
    redirect_uri: 'https://portal-v2.ladon.cloud/auth/callback',
    post_logout_redirect_uri: 'https://portal-v2.ladon.cloud',
    response_type: 'code',
    scope: 'openid profile api:read api:write'
};
