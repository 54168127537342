import {Component, Input, OnInit} from "@angular/core";
import {AbstractControl, FormControl} from "@angular/forms";

@Component({
    selector: 'app-textbox',
    templateUrl: './app-textbox.component.html',
    host: {
        '[class]': 'classNames'
    }
})
export class AppTextBoxComponent implements OnInit {
    @Input() control: FormControl;
    @Input() label: string;
    @Input() placeHolder: string;
    @Input() controlOnly: boolean = false;
    @Input() maxlength: string = null;
    @Input() classNames: string;

    ngOnInit(): void {
        this.classNames = this.classNames || 'col-span-6';

        if (!this.control) {
            console.warn('Control not found.', this);
        }
    }

    get isInvalid() {
        return this.control.invalid && (this.control.touched || this.control.dirty);
    }

    get path(): string {
        return this.getControlPath(this.control, '');
    }

    getControlName(c: AbstractControl): string | null {
        if (!c.parent) return null;
        const formGroup = c.parent.controls;
        return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
    }

    getControlPath(c: AbstractControl, path: string): string | null {
        path = this.getControlName(c) + path;

        if (c.parent && this.getControlName(c.parent)) {
            path = "." + path;
            return this.getControlPath(c.parent, path);
        } else {
            return path;
        }

    }

    get inputClass(): string {
        if (this.isInvalid) {
            return 'form-input block w-full pr-10 sm:text-sm sm:leading-5 border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red'
        } else {
            return 'form-input block w-full sm:text-sm sm:leading-5 border-gray-300 focus:border-blue-300 focus:shadow-outline-blue'
        }
    }

    get errors() {

        if (!this.control.errors) {
            return '';
        }

        if (this.control.errors.required) {
            return `${this.label} is required.`;
        }

        if (this.control.errors.email) {
            return `${this.label} must be a valid email address.`;
        }

        if (this.control.errors.minlength) {
            return `${this.label} must be at least ${this.control.errors.minlength.requiredLength} characters long.`;
        }

        if (this.control.errors.maxlength) {
            return `${this.label} must be at most ${this.control.errors.maxlength.requiredLength} characters long.`;
        }

        if (this.control.errors.pattern) {
            return `${this.label} is not in the correct format.`;
        }

        if (this.control.errors.remote) {
            return this.control.errors.remote.join('. ');
        }

        if (this.control.errors.max) {
            return `${this.label} is must be less or equal to ${this.control.errors.max.max}.`;
        }

        if (this.control.errors.min) {
            return `${this.label} is must be greater or equal to ${this.control.errors.min.min}.`;
        }

        return `${this.label} has an unknown error: ${JSON.stringify(this.control.errors)}`;
    }
}
