<div *ngIf="control" [class]="classNames">
    <label [for]="path" class="block text-sm font-medium leading-5 text-gray-700">
        {{label}}
    </label>
    <select
        class="mt-1 form-select block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        [name]="path"
        [formControl]="control"
        [class.is-invalid]="isInvalid">
        <option *ngIf="required" value="" disabled="disabled">{{requiredText}}</option>
        <option *ngIf="addNone" value="">{{addText}}</option>
        <option *ngFor="let item of items; trackBy: getId" [value]="item.value">{{item.text}}</option>
    </select>
    <div class="mt-2 text-sm text-red-600" *ngIf="isInvalid" [innerHTML]="errors"></div>
</div>
