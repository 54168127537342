<app-textbox [control]="form.get('label')" label="Label"></app-textbox>

<h4 class="mt-6 text-lg font-weight-bold leading-6">
    Flow
</h4>

<p class="mb-3 font-weight-light text-sm text-gray-500">
    Set the direction of the flow.
</p>
<div class="mt-4 grid grid-cols-6 gap-6">
    <div class="col-span-6">
        <app-selectbox label="Action #1"
                       [control]="form.get('successLink')"
                       [items]="getLinkItems()"
                       addNone="true"></app-selectbox>
    </div>
    <div class="col-span-6">
        <app-selectbox label="Action #2"
                       [control]="form.get('successLink2')"
                       [items]="getLinkItems()"
                       addNone="true"></app-selectbox>
    </div>
    <div class="col-span-6">
        <app-selectbox label="Action #3"
                       [control]="form.get('successLink3')"
                       [items]="getLinkItems()"
                       addNone="true"></app-selectbox>
    </div>
</div>



