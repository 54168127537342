import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {AuthService} from "../services";
import {from, Observable} from "rxjs";
import {switchMap} from "rxjs/operators";


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(public auth: AuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.auth.getAccessToken())
            .pipe(
                switchMap(data => {
                    const clone = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${data}`
                        }
                    })
                    return next.handle(clone)
                })
            );
    }
}
