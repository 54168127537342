import {Injectable} from "@angular/core";

import {HttpService, IHttpResult} from "./http.service";

@Injectable({providedIn: 'root'})
export class UserService {

    constructor(private httpService: HttpService) {
    }

    async getSelf(): Promise<IHttpResult<any>> {
        return this.httpService.get<any>(
            this.httpService.getApiUrl('/users/self',1),
        );
    }
}

