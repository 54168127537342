import {Component, Input, OnInit} from "@angular/core";
import {AbstractControl, FormControl} from "@angular/forms";
import {ISelectListItem} from "../../interfaces";

@Component({
    selector: 'app-selectbox',
    templateUrl: './app-selectbox.component.html',
    host: {
        '[class]': 'classNames'
    }
})
export class AppSelectBoxComponent implements OnInit {
    @Input() control: FormControl;
    @Input() label: string;
    @Input() placeHolder: string;
    @Input() items: ISelectListItem[] = [];
    @Input() addNone: boolean = false;
    @Input() addText: string = '(none)';
    @Input() required: boolean = false;
    @Input() requiredText: string = '-- Please Select --';
    @Input() classNames: string;

    ngOnInit(): void {
        this.classNames = this.classNames || 'col-span-6';
    }

    get isInvalid() {
        return this.control.invalid && (this.control.touched || this.control.dirty);
    }

    get path(): string {
        return this.getControlPath(this.control, '');
    }

    getId(index: any, item: ISelectListItem) {
        return item.value;
    }

    getControlName(c: AbstractControl): string | null {
        if (!c.parent) return null;
        const formGroup = c.parent.controls;
        return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
    }

    getControlPath(c: AbstractControl, path: string): string | null {
        path = this.getControlName(c) + path;

        if (c.parent && this.getControlName(c.parent)) {
            path = "." + path;
            return this.getControlPath(c.parent, path);
        } else {
            return path;
        }
    }

    get errors() {

        if (!this.control.errors) {
            return '';
        }

        if (this.control.errors.required) {
            return `${this.label} is required.`;
        }

        if (this.control.errors.email) {
            return `${this.label} must be a valid email address.`;
        }

        if (this.control.errors.minlength) {
            return `${this.label} must be at least ${this.control.errors.minlength.requiredLength} characters long.`;
        }

        if (this.control.errors.pattern) {
            return `${this.label} is not in the correct format.`;
        }

        if (this.control.errors.remote) {
            return this.control.errors.remote.join('. ');
        }

        return `${this.label} has an unknown error: ${JSON.stringify(this.control.errors)}`;
    }
}
