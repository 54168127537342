import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WorkflowService {

    private editors: any = {};

    getWorkflowActions() {
        return this.editors;
    }

    getAction(workflowActionId: string) {
        return this.editors[workflowActionId];
    }

    setActions(actions: any) {
        this.editors = actions;
    }
}
