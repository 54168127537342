import {User, UserManager, UserManagerSettings} from 'oidc-client';
import {Injectable} from "@angular/core";

import {environment} from '../../../environments/environment';
import {BehaviorSubject} from "rxjs";
import {distinctUntilChanged} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class AuthService {


    private currentUserSubject = new BehaviorSubject<any>(null);

    private userManager: UserManager;
    private _isAuthenticated: boolean = false;

    public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

    public constructor() {
        const settings: UserManagerSettings = {
            authority: environment.authority,
            client_id: environment.client_id,
            redirect_uri: environment.redirect_uri, // `${environment.clientRoot}assets/signin-callback.html`,
            //silent_redirect_uri: `${environment.clientRoot}assets/silent-callback.html`,
            post_logout_redirect_uri: environment.post_logout_redirect_uri, //post_logout_redirect_uri: `${environment.clientRoot}`,
            response_type: environment.response_type,
            scope: environment.scope,
        };

        this.userManager = new UserManager(settings);
    }

    public async init(): Promise<boolean> {

        this.userManager.events.addUserSignedOut(() => {
            this.signOut();
        });

        const user = await this.userManager.getUser();

        this.signIn(user);

        return (user && user.access_token) != null;
    }

    public async login(): Promise<void> {
        await this.userManager.signinRedirect();
    }

    public async logout(): Promise<void> {
        await this.userManager.signoutRedirect();
    }

    public async redirectCallback(): Promise<void> {
        try {
            const user = await this.userManager.signinRedirectCallback();

            this.signIn(user);
        } catch (err) {
            console.log(err);
            this.signOut();
        }
    }

    public async getAccessToken(): Promise<string | null> {
        const user = await this.userManager.getUser();

        return user ? user.access_token : null;
    }

    get user(): any {
        return this.currentUserSubject.getValue();
    }

    get isAuthenticated(): boolean {
        return this._isAuthenticated;
    }

    private signIn(user: User): void {
        this._isAuthenticated = user != null;
        this.currentUserSubject.next(user);
    }

    private signOut(): void {
        this._isAuthenticated = false;
        this.currentUserSubject.next(null);
    }
}
