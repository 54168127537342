import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {interval, Subscription} from "rxjs";

import {HttpResult, IHttpResult} from "../../core/services";
import {UserService} from "../../core/services/user.service";
import {HomeService} from "./services/home.service";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
})
export class HomeComponent {

    public dashboardData: IHttpResult<any> = HttpResult.pending;
    public productStatistics: IHttpResult<any[]> = HttpResult.pending;
    public affiliateStatistics: IHttpResult<any[]> = HttpResult.pending;

    public form: FormGroup;

    public user: any = {};
    public sub: Subscription;

    constructor(
        private dashboardService: HomeService,
        private fb: FormBuilder,
        private userService: UserService
    ) {
        this.form = this.fb.group({
            date: this.fb.control(new Date(), [Validators.required])
        });
    }

    ngOnInit(): void {
        this.init().catch(console.error);

        this.sub = interval(3000).subscribe(() => this.updateBackground());
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }

    async init() {
        await this.update();

        let user = await this.userService.getSelf();
        this.user = user.data;
    }

    async update() {
        let formValue = this.form.value;

        this.dashboardData = HttpResult.pending;
        this.dashboardData = await this.dashboardService.getDashboardData(formValue.date);

        await this.refreshSiteStats();
        await this.refreshAffStats();
    }

    async updateBackground() {
        let formValue = this.form.value;

        this.dashboardData = await this.dashboardService.getDashboardData(formValue.date);
        this.productStatistics = await this.dashboardService.getProducts(formValue.date.toISOString());
        this.affiliateStatistics = await this.dashboardService.getAffiliates(formValue.date.toISOString());

        if (!this.dashboardData.isOk || !this.productStatistics.isOk || !this.affiliateStatistics.isOk) {
            this.sub?.unsubscribe();
        }
    }

    async refreshSiteStats() {
        let formValue = this.form.value;
        this.productStatistics = HttpResult.pending;
        this.productStatistics = await this.dashboardService.getProducts(formValue.date.toISOString());
    }

    async refreshAffStats() {
        let formValue = this.form.value;
        this.affiliateStatistics = HttpResult.pending;
        this.affiliateStatistics = await this.dashboardService.getAffiliates(formValue.date.toISOString());
    }
}
