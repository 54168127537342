import {NgModule, Optional, SkipSelf} from "@angular/core";
import {CommonModule} from "@angular/common";

import {AuthService, CoreService, CurrencyService, EventService} from "./services";

import {EnsureModuleLoadedOnceGuard} from "./loaded-once";
import {HasPermissionDirective} from "./has-permission.directive";
import {NotAuthenticatedDirective} from "./not-authenticated.directive";

@NgModule({
    declarations: [HasPermissionDirective, NotAuthenticatedDirective],
    imports: [CommonModule],
    providers: [EventService, CoreService, AuthService, CurrencyService],
    exports: [
        HasPermissionDirective,
        NotAuthenticatedDirective
    ]
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        super(parentModule);
    }
}
