<div class="rounded-md bg-red-100 p-4 mb-6" *ngIf="result && !result.hasPermission">
    <div class="flex">
        <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-red-400" fill="currentColor" viewBox="0 0 20 20">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"/>
            </svg>
        </div>
        <div class="ml-3">
            <h3 class="text-sm leading-5 font-medium text-red-800">
                You do not have permission to view the requested resource.
            </h3>
        </div>
    </div>
</div>

<div class="rounded-md bg-red-100 p-4 mb-6" *ngIf="result && result.hasError">
    <div class="flex">
        <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-red-400" fill="currentColor" viewBox="0 0 20 20">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"/>
            </svg>
        </div>
        <div class="ml-3">
            <h3 class="text-sm leading-5 font-medium text-red-800">
                There was a problem with the request, please try again.
            </h3>
        </div>
    </div>
</div>

<div class="rounded-md bg-red-100 p-4 mb-6" *ngIf="result && result.isInvalid">
    <div class="flex">
        <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-red-400" fill="currentColor" viewBox="0 0 20 20">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"/>
            </svg>
        </div>
        <div class="ml-3">
            <h3 class="text-sm leading-5 font-medium text-red-800">
                There was a problem with the request, please try again.
            </h3>

            <div class="mt-2 text-sm leading-5 text-red-700">
                <ul class="list-disc pl-5" *ngIf="result.data">
                    <li *ngFor="let error of getErrors()" class="mb-1">{{error}}</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div *ngIf="result && !result.isReady">
    <app-spinner [show]="true"></app-spinner>
</div>

<div *ngIf="result && result.isOk">
    <ng-content></ng-content>
</div>
