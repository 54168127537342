export function optionsToObj(options: any): any {
    let result = {};

    if (options === null) {
        return result;
    }

    for (const option of options) {
        result[option.key] = option.value;
    }

    return result;
}

export function objToOptions(obj: any): any {
    let result = [];

    if (obj === null) {
        return result;
    }

    const keys = Object.keys(obj);

    for (const key of keys) {
        result.push({key, value: obj[key]});
    }

    return result;
}
