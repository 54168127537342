import {Component, Input} from "@angular/core";
import {IHttpResult} from "src/app/core/services";

@Component({
    selector: 'app-http-result',
    templateUrl: './app-http-result.component.html'
})
export class AppHttpResultComponent {
    @Input() result: IHttpResult<any>;

    getErrors(): string[] {
        const errs: string[] = [];

        const keys = Object.keys(this.result.data);

        for (const k of keys) {
            errs.push(this.result.data[k]);
        }

        return errs;
    }
}
