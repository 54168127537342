import {Injectable} from '@angular/core';

import {HttpService, IHttpResult} from '../../../core/services';

@Injectable({
    providedIn: 'root'
})
export class HomeService {

    constructor(private httpService: HttpService) {

    }

    async getDashboardData(date: Date): Promise<any> {
        return this.httpService.get<any>(
            this.httpService.getApiUrlV2('/reports/dashboard-stats?date=' + date.toISOString(), 1)
        );
    }

    async getAffiliates(date: string): Promise<IHttpResult<any[]>> {
        return this.httpService.get<any[]>(
            this.httpService.getApiUrlV2('/reports/affiliates', 1),
            {date}
        );
    }

    async getProducts(date: string): Promise<IHttpResult<any[]>> {
        return this.httpService.get<any[]>(
            this.httpService.getApiUrlV2('/reports/products', 1),
            {date}
        );
    }
}

