import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../core/services";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {

    constructor(
        private authService: AuthService
    ) {

    }

    ngOnInit(): void {
        this.authService.login().catch(err => {
            console.error(err);
        });
    }
}
