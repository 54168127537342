import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BreadcrumbComponent} from "../layout/breadcrumb/breadcrumb.component";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxGraphModule} from "@swimlane/ngx-graph";

import {AppHttpResultComponent} from "./components/app-http-result/app-http-result.component";
import {AppSpinnerComponent} from "./components/app-spinner/app-spinner.component";
import {
    DatePipe,
    DateTimePipe,
    MoneyPipe,
    SafePipe,
    TimePipe,
    UtcDatePipe,
    UtcDateTimePipe
} from "./pipes/pipes";
import {AppHttpAlertComponent} from "./components/app-http-alert/app-http-alert.component";
import {AppTextBoxComponent} from "./components/app-textbox/app-textbox.component";
import {AppTextAreaComponent} from "./components/app-textarea/app-textarea.component";
import {AppCheckBoxComponent} from "./components/app-checkbox/app-checkbox.component";
import {AppSelectBoxComponent} from './components/app-selectbox/app-selectbox.component';
import {AppStaticBoxComponent} from "./components/app-staticbox/app-staticbox.component";
import {AppMoneyBoxComponent} from "./components/app-moneybox/app-moneybox.component";
import {AppPasswordComponent} from "./components/app-password/app-password.component";
import {AppSwitchBoxComponent} from "./components/app-switchbox/app-switchbox.component";
import {FlyoutComponent} from "./components/app-flyout/flyout.component";
import {AppButtonComponent} from "./components/app-button/app-button.component";
import {ValueModifierDirective} from "./directives/value-modifier.directive";
import {WorkflowActionRegistry} from "./components/workflow-editor/workflow-action-registry.service";
import {AppWorkflowEditorComponent} from "./components/workflow-editor/workflow-editor.component";
import {ActionEditorDirective} from "./components/workflow-editor/action-editor.directive";
import {CompleteActionComponent} from "./components/workflow-editor/actions/complete-action.component";
import {StartActionComponent} from "./components/workflow-editor/actions/start-action.component";
import {WaitActionComponent} from "./components/workflow-editor/actions/wait-action.component";
import {JumpWorkflowActionComponent} from "./components/workflow-editor/actions/jump-workflow-action.component";
import {ParallelActionComponent} from "./components/workflow-editor/actions/parallel-action.component";
import {AuthorizePaymentActionComponent} from "./components/workflow-editor/actions/authorize-payment-action.component";
import {CapturePaymentActionComponent} from "./components/workflow-editor/actions/capture-payment-action.component";
import {ConditionalActionComponent} from "./components/workflow-editor/actions/conditional-action.component";
import {AppDatePickerComponent} from "./components/app-datepicker/app-datepicker.component";
import {AppDifferenceComponent} from "./components/app-difference/difference.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxGraphModule,
    ],
    providers:[
        //
        WorkflowActionRegistry,
    ],
    entryComponents: [
        CompleteActionComponent,
        StartActionComponent,
        AuthorizePaymentActionComponent,
        CapturePaymentActionComponent,
        WaitActionComponent,
        JumpWorkflowActionComponent,
        ParallelActionComponent,
        ConditionalActionComponent,
    ],
    declarations: [
        DatePipe,
        DateTimePipe,
        MoneyPipe,
        SafePipe,
        TimePipe,
        UtcDatePipe,
        UtcDateTimePipe,
        //
        AppHttpResultComponent,
        AppSpinnerComponent,
        AppHttpAlertComponent,
        BreadcrumbComponent,
        //
        AppTextBoxComponent,
        AppTextAreaComponent,
        AppCheckBoxComponent,
        AppSelectBoxComponent,
        AppStaticBoxComponent,
        AppDatePickerComponent,
        AppMoneyBoxComponent,
        AppPasswordComponent,
        AppSwitchBoxComponent,
        AppButtonComponent,
        AppDifferenceComponent,
        FlyoutComponent,
        //
        ValueModifierDirective,
        //
        CompleteActionComponent,
        StartActionComponent,
        AuthorizePaymentActionComponent,
        CapturePaymentActionComponent,
        WaitActionComponent,
        JumpWorkflowActionComponent,
        ParallelActionComponent,
        ConditionalActionComponent,
        //
        AppWorkflowEditorComponent,
        ActionEditorDirective,
    ],
    exports: [
        DatePipe,
        DateTimePipe,
        MoneyPipe,
        SafePipe,
        TimePipe,
        UtcDatePipe,
        UtcDateTimePipe,
        //
        AppHttpResultComponent,
        AppHttpAlertComponent,
        AppSpinnerComponent,
        BreadcrumbComponent,
        //
        AppTextBoxComponent,
        AppTextAreaComponent,
        AppCheckBoxComponent,
        AppSelectBoxComponent,
        AppStaticBoxComponent,
        AppDatePickerComponent,
        AppMoneyBoxComponent,
        AppPasswordComponent,
        AppSwitchBoxComponent,
        AppButtonComponent,
        AppDifferenceComponent,
        //
        FlyoutComponent,
        //
        ValueModifierDirective,
        //
        AppWorkflowEditorComponent,
        ActionEditorDirective,
    ]
})
export class SharedModule {
    constructor(ws: WorkflowActionRegistry) {
        ws.initialize();
    }
}
