<div class="flex flex-col w-64 bg-gray-900">
    <div class="h-0 flex-1 flex flex-col pt-5 overflow-y-auto">
        <div class="flex items-center flex-shrink-0 px-4">
            <img class="h-8 w-auto" src="/assets/images/workflow-logo-on-dark.svg" alt="Workflow"/>
        </div>
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <nav class="mt-5 flex-1 px-2 bg-gray-800">
            <div *ngFor="let menuGroup of menuItems" class="pb-4">
                <h2 class="ml-1 mt-4 mb-3 text-lg font-weight-light leading-6 text-white">{{menuGroup.text}}</h2>
                <a *ngFor="let menuItem of menuGroup.items"
                   [routerLink]="menuItem.href"
                   routerLinkActive="bg-gray-900"
                   class="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition ease-in-out duration-150">
                    <svg
                        class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150"
                        fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"/>
                    </svg>
                    {{menuItem.text}}
                </a>
            </div>
        </nav>
    </div>
</div>
