import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {getMenuItems, IMenuGroup, IMenuItem} from "../menu";
import {NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-mobile-menu',
    templateUrl: './mobile-menu.component.html'
})
export class MobileMenuComponent implements OnDestroy {
    @Input() isExpanded = false;
    @Output() isExpandedChange = new EventEmitter<boolean>();

    private sub: Subscription;

    constructor(router: Router) {
        this.sub = router.events.subscribe(value => {
            if (value instanceof NavigationEnd) {
                this.isExpanded = false;
                this.isExpandedChange.emit(this.isExpanded);
            }
        });
    }

    toggle() {
        this.isExpanded = !this.isExpanded;
        this.isExpandedChange.emit(this.isExpanded);
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    get menuItems(): IMenuGroup[] {
        return getMenuItems();
    }

    get menuCss(): string {
        return this.isExpanded ? "translate-x-0" : "-translate-x-full";
    }

    get bgCss(): string {
        return this.isExpanded ? "block" : "hidden";
    }
}
