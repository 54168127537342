import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

import {IGraphLink, GraphNode, IWorkflowActionComponent} from '../workflow-action.component';

@Component({
    templateUrl: './jump-workflow-action.component.html'
})
export class JumpWorkflowActionComponent implements IWorkflowActionComponent, OnInit {
    @Input() id: string;
    @Input() workflowActionId: string;
    @Input() node: any;

    @Input() nodes: GraphNode[];
    @Input() links: IGraphLink[];

    public form: FormGroup;
    public submitting: boolean = false;

    constructor(
        private fb: FormBuilder
    ) {
        this.form = this.fb.group({
            'label': this.fb.control('', []),
            'settings': this.fb.group({})
        });
    }

    ngOnInit(): void {
        this.form.patchValue({
            'label': this.node.label,
            'settings': this.node.settings
        });
    }

    save() {
        let data = this.form.value;
        let links: IGraphLink[] = [];

        return {data, links};
    }
}
