<div *ngIf="isAuthenticated">
    <div class="h-screen flex overflow-hidden bg-gray-100" *hasPermission="[]">
        <app-mobile-menu [(isExpanded)]="isExpanded"></app-mobile-menu>
        <div app-desktop-menu class="hidden md:flex md:flex-shrink-0"></div>

        <div class="flex flex-col w-0 flex-1 overflow-hidden">
            <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
                <button (click)="toggle()"
                        class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150"
                        aria-label="Open sidebar">
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>
                    </svg>
                </button>
            </div>
            <main class="flex-1 relative z-0 overflow-y-auto outline-none" tabindex="0">
                <router-outlet></router-outlet>
            </main>
        </div>
    </div>
</div>
<div *ngIf="!isAuthenticated">
    <router-outlet></router-outlet>
</div>
