import {Pipe, PipeTransform} from '@angular/core';

import * as moment from 'moment';
import {Money} from "../../core/helpers/money";
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
    name: 'ldate'
})
export class DatePipe implements PipeTransform {
    transform(val) {
        return val ? moment(val).format('L') : '-';
    }
}

@Pipe({
    name: 'utcdate'
})
export class UtcDatePipe implements PipeTransform {
    transform(val) {
        return val ? moment(val).format('MMMM Do YYYY') : '-';
    }
}

@Pipe({
    name: 'utcdatetime'
})
export class UtcDateTimePipe implements PipeTransform {
    transform(val) {
        return val ? moment(val).format('MMMM Do YYYY HH:mm:ss') : '-';
    }
}

@Pipe({
    name: 'llldatetime'
})
export class DateTimePipe implements PipeTransform {
    transform(val) {
        return val ? moment(val).format('LLL') : '-';
    }
}

@Pipe({
    name: 'time'
})
export class TimePipe implements PipeTransform {
    transform(val) {
        return val ? moment(val).format('HH:mm:ss') : '-';
    }
}

@Pipe({name: 'safeHtml'})
export class SafePipe {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(style) {
        return this.sanitizer.bypassSecurityTrustHtml(style);
    }
}

@Pipe({
    name: 'money'
})
export class MoneyPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(val) {
        if (!val) {
            return '-';
        }

        return this.sanitizer.bypassSecurityTrustHtml(Money.formatCurrency(val.unitValue, val.currencyCode));
    }
}
