import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";

import {AuthService} from "../services";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const isAuthenticated = this.authService.isAuthenticated;

        if (isAuthenticated) {
            console.log(`[AuthGuard] Authenticated for route '${state.url}'.`);
            // logged in so return true
            return true;
        }

        console.log('[AuthGuard] Auth guard failed, not authenticated.');

        // not logged in so redirect to login page with the return url
        this.router
            .navigate(['/login'], {queryParams: {returnUrl: state.url}})
            .catch(console.error);

        return false;
    }
}
